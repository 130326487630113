import React from 'react'
import { Card } from 'react-bootstrap'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import Container from 'react-bootstrap/esm/Container'
import Badge from 'react-bootstrap/esm/Badge'
import { Link } from 'react-router-dom'

const ShopsBar = () => {
    const data = [
        {category: 'SuperMarkets', image: "/images/shop-categories/supermarkets-uganda.png"},
        {category: 'Baby', image: "/images/shop-categories/babycare-and-toys-stores.png"},
        {category: 'Water And Beverage', image: "/images/shop-categories/water-and-beverage-stores.png"},
        {category: 'Bakery', image: "/images/shop-categories/bakery-and-cake-stores.jpeg"},
        {category: 'Butchery and Seafood', image: "/images/shop-categories/butchery-and-seafood.png"},
        {category: 'Electronics', image: "/images/shop-categories/electronics-stores.png"},
        {category: 'Fitness and Nutrition', image: "/images/shop-categories/fitness-and-nutrition-stores.png"},
        {category: 'Flowers and Gifts', image: "/images/shop-categories/flower-and-gift-shops.jpeg"},
        {category: 'Fruits and Vegetables', image: "/images/shop-categories/fruits-and-vegetables-stores.png"},
        {category: 'Home And Kitchen', image: "/images/shop-categories/home-and-kitchen-stores.png"},
        {category: 'Organic Shops', image: "/images/shop-categories/organic-shops-stores.png"},
        {category: 'Perfumes and Scents', image: "/images/shop-categories/perfume-and-scent-stores.jpeg"},
        {category: 'Pets Supplies', image: "/images/shop-categories/pets-and-petfood-stores.png"},
        {category: 'Pharmacies', image: "/images/shop-categories/pharmacies-off-the-counter-meds.png"},
        {category: 'Ugandan Food', image: "/images/shop-categories/resturants-and-fastfood-stores.png"},
        {category: 'Grocery', image: "/images/shop-categories/spicies-and-ingridient-stores.png"},
        {category: 'Staionery And Party Supplies', image: "/images/shop-categories/stationery-and-party-supplies-stores.png"},
        {category: 'Butchery and Seafood', image: "/images/shop-categories/butchery-and-seafood.png"},

    ]
  return (
      <Row className="g-3 border-0 py-3 mt-3">
        {data.map((item, index) => (
          <Col key={index} xs={6} md={3} lg={2} className='border-0'>
            <Link
              to={{
                pathname: '/shops',
                search: `?category=${encodeURIComponent(item.category)}`
              }}
              className="text-decoration-none"
            >
              <Card>
                <div className="position-relative" style={{ height: '120px' }}>
                  <Card.Img 
                    src={item.image}
                    alt={item.category}
                    className="h-100 w-100"
                    style={{ objectFit: 'contain' }}
                  />
                  <div 
                    className="position-absolute bottom-0 start-0 w-100 p-2"
                    style={{ 
                      background: 'linear-gradient(transparent, rgba(0,0,0,0.1))',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Badge className='bg-light text-success p-2 d-inline-block text-truncate'>
                      {item.category}
                    </Badge>
                  </div>
                </div>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
  )
}

export default ShopsBar
