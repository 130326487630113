import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Stack,
  Form,
  Spinner,
  Image,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { getError } from '../utils';
import Rating from '../components/Rating';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import Accessory from '../components/Accessory';
import {Tabs, Tab} from 'react-bootstrap';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH_PRODUCT':
      return { ...state, product: action.payload };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreateReview: true };
    case 'CREATE_SUCCESS':
      return { ...state, loadingCreateReview: false };
    case 'CREATE_FAIL':
      return { ...state, loadingCreateReview: false };
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, product: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  const reviewsRef = useRef(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [activeTab, setActiveTab] = useState('description');

  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;

  const [{ loading, error, product, loadingCreateReview }, dispatch] = useReducer(reducer, {
    product: [],
    loading: true,
    error: '',
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(`https://api.ugyard.com/api/products/slug/${slug}`);
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
        setSelectedImage(result.data.image); // Set main image as default
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };
    fetchData();
  }, [slug]);

  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const qty = existItem ? existItem.quantity + quantity : quantity;
    
    try {
      const { data } = await axios.get(`https://api.ugyard.com/api/products/${product._id}`);
      if (data.countInStock < qty) {
        toast.error('Sorry. Product is out of stock');
        return;
      }
      
      ctxDispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...product, quantity: qty },
      });
      
      toast.success('Product added to cart');
      navigate('/cart');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const submitReviewHandler = async (e) => {
    e.preventDefault();
    if (!comment || !rating) {
      toast.error('Please enter both rating and comment');
      return;
    }
    
    try {
      const { data } = await axios.post(
        `https://api.ugyard.com/api/products/${product._id}/reviews`,
        { rating, comment, name: userInfo.name },
        { headers: { Authorization: `Bearer ${userInfo.token}` } }
      );

      dispatch({ type: 'CREATE_SUCCESS' });
      toast.success('Review submitted successfully');
      
      // Update product with new review
      const updatedProduct = { 
        ...product,
        reviews: [data.review, ...product.reviews],
        numReviews: data.numReviews,
        rating: data.rating
      };
      
      dispatch({ type: 'REFRESH_PRODUCT', payload: updatedProduct });
      
      // Scroll to reviews section
      reviewsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      toast.error(getError(error));
      dispatch({ type: 'CREATE_FAIL' });
    }
  };

  const shareProduct = () => {
    navigator.clipboard.writeText(`https://ugyard.com/product/${product.slug}`)
      .then(() => toast.success('Link copied to clipboard'))
      .catch(() => toast.error('Failed to copy link'));
  };

  if (loading) return <LoadingBox />;
  if (error) return <MessageBox variant="danger">{error}</MessageBox>;

  return (
    <Container className="py-1" fluid>
      <Helmet>
        <title>{product.name}</title>
        <meta name="description" content={product.description.substring(0, 160)} />
      </Helmet>

      {/* Product Main Section */}
      <Row className="g-2 mb-5">
        {/* Product Images */}
        <Col lg={4}>
          <div className="d-flex">
            {/* Thumbnails */}
            <Stack gap={2} className="me-3" style={{ width: '80px' }}>
              {product.images?.map((img) => (
                <Button
                  key={img}
                  variant="light"
                  className={`p-0 border ${selectedImage === img ? 'border-primary' : ''}`}
                  onClick={() => setSelectedImage(img)}
                >
                  <Image src={img} alt="thumbnail" fluid thumbnail />
                </Button>
              ))}
            </Stack>
            
            {/* Main Image */}
            <div className="flex-grow-1 position-relative">
              <Image
                src={selectedImage || product.image}
                alt={product.name}
                fluid
                className="rounded-3 border"
                style={{ maxHeight: '500px', objectFit: 'contain' }}
              />
              {product.inStock === 0 && (
                <div className="position-absolute top-0 start-0 bg-danger text-white p-2 rounded-end">
                  Out of Stock
                </div>
              )}
            </div>
          </div>
        </Col>

        {/* Product Info */}
        <Col lg={5}>
          <div className="d-flex flex-column h-100">
            <div className="mb-3">
              <h1 className="mb-2">{product.name}</h1>
              <div className="d-flex align-items-center mb-3">
                <Rating rating={product.rating} />
                <span className="ms-2 text-muted">
                  ({product.numReviews} reviews)
                </span>
                <Button 
                  variant="link" 
                  className="ms-auto text-dark"
                  onClick={shareProduct}
                >
                  <i className="fas fa-share-alt"></i> Share
                </Button>
              </div>
              
              {product.shopName && (
                <div className="mb-3">
                  <span className="text-muted">Sold by: </span>
                    {product.shopName}
                </div>
              )}
            </div>

            {/* Price Section */}
            <div className="mb-4">
              <h3 className="text-primary mb-0">
                UGX {product.price?.toLocaleString()}
              </h3>
              {product.aed && (
                <div className="text-muted">
                  ≈ AED {product.aed?.toLocaleString()}
                </div>
              )}
            </div>

            {/* Product Actions */}
            <div className="mt-auto">
              {product.inStock > 0 && (
                <div className="d-flex align-items-center mb-3">
                  <span className="me-2">Quantity:</span>
                  <Form.Select 
                    style={{ width: '80px' }}
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                  >
                    {[...Array(Math.min(product.inStock, 10)).keys()].map((x) => (
                      <option key={x + 1} value={x + 1}>
                        {x + 1}
                      </option>
                    ))}
                  </Form.Select>
                  <span className="ms-2 text-muted">
                    {product.inStock} available
                  </span>
                </div>
              )}

              <Button
                variant="primary"
                size="lg"
                className="w-100 mb-3"
                onClick={addToCartHandler}
                disabled={product.inStock === 0}
              >
                {product.inStock > 0 ? 'Add to Cart' : 'Out of Stock'}
              </Button>

              <div className="d-flex justify-content-between small text-muted">
                <span>
                  <i className="fas fa-undo me-1"></i> Free returns
                </span>
                <span>
                  <i className="fas fa-shield-alt me-1"></i> Secure payment
                </span>
                <span>
                  <i className="fas fa-truck me-1"></i> Fast delivery
                </span>
              </div>
            </div>
          </div>
        </Col>

        {/* Highlights/Features */}
        <Col lg={3}>
          <Card className="h-100">
            <Card.Body>
              <h5 className="mb-3">Highlights</h5>
              <ListGroup variant="flush">
                {product.features?.map((feature, index) => (
                  <ListGroup.Item key={index} className="d-flex">
                    <i className="fas fa-check text-success me-2 mt-1"></i>
                    <span>{feature}</span>
                  </ListGroup.Item>
                )) || (
                  <ListGroup.Item>
                    No features listed
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Product Details Tabs */}

  <div className="mb-5">
  <Tabs
    activeKey={activeTab}
    onSelect={(k) => setActiveTab(k)}
    id="product-details-tabs"
    className="mb-4 border-bottom"
  >
    <Tab 
      eventKey="description" 
      title="Description"
      tabClassName={activeTab === 'description' ? 'text-primary' : 'text-dark'}
    >
      <h4 className="mb-3 mt-3">Product Description</h4>
      <div className="whitespace-pre-line">
        {product.description}
      </div>
    </Tab>
    
    <Tab 
      eventKey="specs" 
      title="Specifications"
      tabClassName={activeTab === 'specs' ? 'text-primary' : 'text-dark'}
    >
      <h4 className="mb-3 mt-3">Specifications</h4>
      {product.specifications ? (
        <table className="table">
          <tbody>
            {Object.entries(product.specifications).map(([key, value]) => (
              <tr key={key}>
                <td style={{ width: '30%' }} className="text-muted">{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <MessageBox>No specifications available</MessageBox>
      )}
    </Tab>
    
    <Tab 
      eventKey="reviews" 
      title={`Reviews (${product.numReviews})`}
      tabClassName={activeTab === 'reviews' ? 'text-primary' : 'text-dark'}
    >
      <div ref={reviewsRef}>
        <h4 className="mb-3 mt-3">Customer Reviews</h4>
        
        {product.reviews.length === 0 ? (
          <MessageBox>No reviews yet. Be the first to review!</MessageBox>
        ) : (
          <ListGroup variant="flush">
            {product.reviews.map((review) => (
              <ListGroup.Item key={review._id} className="mb-3">
                <div className="d-flex justify-content-between">
                  <strong>{review.name}</strong>
                  <small className="text-muted">
                    {new Date(review.createdAt).toLocaleDateString()}
                  </small>
                </div>
                <Rating rating={review.rating} />
                <p className="mt-2 mb-0">{review.comment}</p>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}

        {userInfo ? (
          <div className="mt-4">
            <h5>Write a Review</h5>
            <Form onSubmit={submitReviewHandler}>
              <Form.Group className="mb-3">
                <Form.Label>Rating</Form.Label>
                <Form.Select
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                  required
                >
                  <option value="">Select Rating</option>
                  <option value="1">1 - Poor</option>
                  <option value="2">2 - Fair</option>
                  <option value="3">3 - Good</option>
                  <option value="4">4 - Very Good</option>
                  <option value="5">5 - Excellent</option>
                </Form.Select>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Review</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
              </Form.Group>
              
              <Button 
                type="submit" 
                variant="primary"
                disabled={loadingCreateReview}
              >
                {loadingCreateReview ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Submitting...
                  </>
                ) : 'Submit Review'}
              </Button>
            </Form>
          </div>
        ) : (
          <MessageBox>
            Please{' '}
            <Link to={`/signin?redirect=/product/${product.slug}`}>
              Sign In
            </Link>{' '}
            to write a review
          </MessageBox>
        )}
      </div>
    </Tab>
  </Tabs>
</div>

      {/* Accessories */}
      {product.accessories?.length > 0 && (
        <div className="mb-5">
          <h4 className="mb-4">Frequently Bought Together</h4>
          <Row xs={2} md={3} lg={4} className="g-3">
            {product.accessories.map((accessory) => (
              <Col key={accessory._id}>
                <Accessory accessory={accessory} />
              </Col>
            ))}
          </Row>
        </div>
      )}

      {/* Related Products (you would fetch these from API) */}
      {/* <div className="mb-5">
        <h4 className="mb-4">You May Also Like</h4>
        <Row xs={2} md={3} lg={4} className="g-3">
          {relatedProducts.map((product) => (
            <Col key={product._id}>
              <ProductCard product={product} />
            </Col>
          ))}
        </Row>
      </div> */}
    </Container>
  );
}

export default ProductScreen;