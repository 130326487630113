import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect, useState } from 'react';
import { Store } from './Store';
import axios from 'axios';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import {
  Navbar,
  Badge,
  Button,
  Offcanvas,
  Col,
  Form,
  InputGroup,
  Accordion,
  Nav,
} from 'react-bootstrap';
import TrustBox from './components/TrustBox';
import LoadingBox from './components/LoadingBox';
import ProtectedRoute from './components/ProtectedRoute';

import HomeScreen from './screens/HomeScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import SigninScreen from './screens/SigninScreen';
import SignupScreen from './screens/SignupScreen';
import ShippingAddressScreen from './screens/ShippingAddressScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import ProfileScreen from './screens/ProfileScreen';
import SearchScreen from './screens/SearchScreen';
import MapScreen from './screens/MapScreen';
import ForgetPasswordScreen from './screens/ForgetPasswordScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ShopScreen from './screens/ShopScreen';
import ShopsSearch from './screens/ShopsSearch';

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo, signInDate } = state;
  const [categoryMenu, setCategoryMenu] = useState(false);
  const [viewServices, setViewServices] = useState(false)
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      const storedCategories = localStorage.getItem('yardCategories');
      if (storedCategories) {
        setCategories(JSON.parse(storedCategories));
      } else {
        try {
          const { data } = await axios.get('https://api.ugyard.com/api/category');
          setCategories(data);
          localStorage.setItem('yardCategories', JSON.stringify(data));
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      }
      setIsLoading(false);
    };

    const fetchUserLocation = async () => {
      try {
        const response = await fetch(
          'https://api.geoapify.com/v1/ipinfo?apiKey=7c85d03e7b3b4639a8c7fc7a9cc38ea8'
        );
        const locationData = await response.json();
        console.log(locationData)
        setLocation(locationData);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchCategories();
    fetchUserLocation();
  }, [userInfo]);


  useEffect(() => {
    if (signInDate) {
      const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const lastSignInTimeMillis = parseInt(signInDate, 10);

      if (currentTime - lastSignInTimeMillis >= twoDaysInMillis) {
        localStorage.removeItem('userInfo');
        ctxDispatch({ type: 'USER_SIGNOUT' });
      }
    }
  }, [signInDate, ctxDispatch]);

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/signin';
  };


  const toggleCategoryMenu = () => {
    setCategoryMenu((prev) => !prev);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      window.location.href = `/search?searchQuery=${searchQuery}`;
    }
  };

  return (
    <BrowserRouter>
      <ToastContainer position="top-center" limit={1} />
      {/* Seller Banner and App Download Buttons */}
      <div className='p-1 bg-light'>
        <p className='text-success'>{location.city?.name}{' '}{location.country?.name}</p>
      </div>
      <div className="d-flex w-full justify-content-between align-items-center border-bottom position-relative">
          <Link to="/">
              <img src="/images/ugyard-logo-2.png" width={100} alt="ugyard-logo" className=" m-2" />
            </Link>
        <div className="align-right border rounded m-1 align-items-center">
          <a href='https://play.google.com/store/apps/details?id=com.mervynstunner.ugyard&pcampaignid=web_share' className='p-1'>
            <i className="fa-brands fa-google-play p-2" style={{ fontSize: 20, color: 'black'}} />
          </a>
          <a href='https://apps.apple.com/ug/app/ugyard/id6739644396' className='p-2'>
            <i className="fa-brands fa-apple p-2" style={{fontSize: 25, color: 'black'}} />
          </a>
          <span onClick={()=> setViewServices(!viewServices)}>
            <i className='fas fa-grip p-1' style={{fontSize: 25}}/>
          </span>
        </div>
      {viewServices && (
        <div className={`services-container ${viewServices ? 'visible' : ''} shadow-lg`}>
          <div className="services-box">
            <a href='/'>
              <img src='/images/servicecons/ugyardtools.png' alt="Tools" />
              <small>Tools</small>
            </a>
          <a href='https://business.ugyard.com'>
              <img src='/images/servicecons/cargo-tools.png' alt="Tools" />
              <small>Cargo</small>
            </a>
            <a href='https://business.ugyard.com'>
              <img src='/images/servicecons/financing-tools.png' alt="Tools" />
              <small>Business</small>
            </a>
            <a href='/'>
              <img src='/images/servicecons/sellers-tools.png' alt="Tools" />
            <small>Financing</small>
        </a>
      </div>
        <span className='border p-2 rounded m-1' onClick={()=> setViewServices(false)}>
          <Link to={'/signin'} className='btn btn-sm'>
            Get started
          </Link>
        </span>
    </div>
    )}
    </div>

      {/* Navigation */}
      <Navbar variant="light" className='px-1'> 
        <Nav className='col-12 col-lg-3 col-md-12'>
          <InputGroup>
          <Button 
            variant="outline-secondary" 
            className="me-2 d-flex align-items-center" 
            onClick={toggleCategoryMenu}
          >
            <i className="fas fa-bars" />
            </Button>
              <Form.Control 
                placeholder="Search products..." 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button variant="success" type="submit" onClick={handleSearch}>
                <i className="fas fa-search" />
              </Button>
            </InputGroup>
        </Nav>
      </Navbar>

      {/* Categories Left Side Menu */}
      <Offcanvas 
        show={categoryMenu} 
        onHide={toggleCategoryMenu} 
        placement="start"
        className="categories-sidebar mt-5 border rounded"
        style={{ maxWidth: '300px'}}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENU</Offcanvas.Title>
        </Offcanvas.Header>
        <div className='flex-row border m-2 rounded bg-light shadow-sm'>
          <Nav className="flex-row justify-content-between">
            <Nav.Link as={Link} to='cart' onClick={toggleCategoryMenu} className='position-relative'>
            <i className=" nav-icon fas fa-shopping-basket fs-5" />
            {cart.cartItems.length > 0 && (
              <Badge pill bg="danger" className="position-absolute top-0 start-95 translate-middle">
                {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              </Badge>
            )}
            </Nav.Link>
            <Nav.Link as={Link} to="/profile" onClick={toggleCategoryMenu}>
              <i className=" nav-icon fas fa-user"></i>
            </Nav.Link>
            <Nav.Link as={Link} to="/orderhistory" onClick={toggleCategoryMenu}>
              <i className=" nav-icon fas fa-history"></i>
            </Nav.Link>
            <Button variant="danger" className="d-flex align-items-center justify-self-send" onClick={signoutHandler}>
              <i className=" nav-icon fas fa-sign-out-alt"></i>
            </Button>
            <hr />
          </Nav>

          </div>
        <Offcanvas.Body className="p-0">
          {isLoading ? (
            <LoadingBox />
          ) : (
            <Accordion flush>
              {categories.map((category) => (
                <Accordion.Item eventKey={category._id} key={category._id}>
                  <Accordion.Header>{category.name}</Accordion.Header>
                  <Accordion.Body className="p-0">
                    <Nav className="flex-column">
                      {category.subcategories && category.subcategories.map((subcategory) => (
                        <Nav.Link 
                          as={Link} 
                          to={`/search?categoryName=${category.name}&subcategory=${subcategory}`}
                          key={subcategory}
                          onClick={toggleCategoryMenu}
                          className="py-2 px-4 border-bottom"
                        >
                          {subcategory}
                        </Nav.Link>
                      ))}
                      {!category.subcategories?.length && (
                        <Nav.Link 
                          as={Link} 
                          to={`/search?categoryName=${category.name}`}
                          onClick={toggleCategoryMenu}
                          className="py-2 px-4 nav-link"
                        >
                          View All
                        </Nav.Link>
                      )}
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Routes */}
      <Routes>
        <Route path="/product/:slug" element={<ProductScreen />} />
        <Route path="/shop/:slug" element={<ShopScreen />} />
        <Route path="/shops" element={<ShopsSearch />} />
        <Route path="/cart" element={<CartScreen />} />
        <Route path="/search" element={<SearchScreen />} />
        <Route path="/signin" element={<SigninScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/forgot-password" element={<ForgetPasswordScreen />} />
        <Route path="/reset-password/:token" element={<ResetPasswordScreen />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfileScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/map"
          element={
            <ProtectedRoute>
              <MapScreen />
            </ProtectedRoute>
          }
        />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route
          path="/order/:id"
          element={
            <ProtectedRoute>
              <OrderScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orderhistory"
          element={
            <ProtectedRoute>
              <OrderHistoryScreen />
            </ProtectedRoute>
          }
        />
        <Route path="/shipping" element={<ShippingAddressScreen />} />
        <Route path="/payment" element={<PaymentMethodScreen />} />
        <Route path="/" element={<HomeScreen />} />
      </Routes>

      {/* Footer */}
      <footer className="text-dark my-1 bg-light my-2">
        <FloatingWhatsApp phoneNumber="+256782144414" accountName="ugyard" />
        <Col md={8}>
          <h5 className="text-dark display-5">BUY, SELL, & TRANSPORT!</h5>
          <p className="text-weight-light">
            Whether you're an individual or a business owner in Uganda or the UAE, UGYARD is here to simplify your life.
            We support nearly 20 business categories across various industries, offering tailored features designed
            for sellers, transporters, and buyers alike.
          </p>
          <ul>
            <li>Effortless Online Presence</li>
            <li>Diverse Categories</li>
            <li>Seller-Friendly Features</li>
            <li>Reliable Transportation</li>
            <li>Buyer Convenience</li>
          </ul>
          Experience the future of commerce & marketplaces — join UGYARD today to reach millions of online shoppers
          in Uganda and UAE!
        </Col>
        <div className='d-flex flex-row flex-wrap justify-content-between p-3 align-start bg-success'>
          <a className='text-light' href='https://ugyard.com/about.html'>ABOUT</a>
          <a className='text-light' href='https://ugyard.com/return-policy.html'>RETURN POLICY</a>
          <a className='text-light' href='https://ugyard.com/privacy-policy.html'>PRIVACY POLICY</a>
          <a className='text-light' href='https://business.ugyard.com/'>BUSINESS TOOLS</a>
          <a className='text-light' href='https://www.instagram.com/ugyard/'>INSTAGRAM</a>
        </div>
        <TrustBox/>
      </footer>
    </BrowserRouter>
  );
}

export default App;