import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../Store';
import {toast} from 'react-toastify'


function Product(props) {
  const { product } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {cart, cart: { cartItems }} = state;

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(`https://api.ugyard.com/api/products/${item._id}`);
    if (data.inStock < quantity) {
      window.alert('Sorry. Product is out of stock');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
    toast.success(
      <Link to='/cart'>{cart.cartItems.reduce((a, c)=> a + c.quantity, 1)}: View Cart</Link>
    )
  };

  return (
  <Card className="product" key={product.sku} style={{ position: 'relative' }}>
  <i className='fas fa-share align-self-end p-2'
    onClick={(e) => {
      e.stopPropagation(); 
      navigator.clipboard.writeText(`https://ugyard.com/product/${product.slug}`);
      toast.success('Copied');
    }}
  />  
  <Link to={`/product/${product.slug}`} style={{ position: 'relative', zIndex: 1 }}>
    <Card.Body className="card-img-wrapper" style={{ backgroundImage: `url(${product.image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat:"no-repeat"}}>
        <div className="overlay">
        {/* <div className="d-inline-block text-truncate position-absolute top-0" style={{maxWidth: '100%'}}>
          <strong className='p-0'>{product.name}</strong>
        </div> */}
{/*           <Rating rating={product.rating} numReviews={product.numReviews} />
 */}          <Card.Text className="d-flex flex-column">
{/*             <small><sup>AED:</sup><strong>{product.aed.toLocaleString() || 0}</strong></small>
 */}{/*             <small className="text-muted">{product.shopName || 'Unlisted'}: seller</small>
            <small className="text-muted">{product.inStock || 0}: Pieces Available for delivery</small>
            <small className="text-muted">{Math.ceil(Math.random() * 10)}%: Discount on Delivery</small> */}
          </Card.Text>
          {product.inStock === 0 ? (
            <Button variant="light" disabled>
              Out of stock
            </Button>
          ) : (
            <button onClick={()=> addToCartHandler(product)} className='text-light btn btn-outline-light'>
              <span> 
                <i className='fas fa-shopping-cart'/>
                <small>
                  <strong>{product.price?.toLocaleString() || 0}</strong>
                  <sup>UGX</sup>
                </small>
              </span>
            </button>
          )}
        </div>
    </Card.Body>
  </Link>
</Card>
  );
}
export default Product;
